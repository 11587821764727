// Swiper carousels
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';
Swiper.use ([Navigation, Pagination, Autoplay]); // Use the required function

const swiperHero = new Swiper('.hero--carousel .swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  speed: 2000,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.hero .carousel--pagination',
    clickable: true
  }
});
const swiperTestimonials = new Swiper('.testimonials--carousel', {
  // Optional parameters
  direction: 'horizontal',
  slidesPerView: 3,
  loop: true,
  pagination: {
    el: '.testimonials .carousel--pagination',
    clickable: true
  },
  breakpoints: {
    767: {
      slidesPerView: 4,
    },
    1201: {
      slidesPerView: 2,
      navigation: {
        nextEl: '.testimonials .carousel--btn-next',
        prevEl: '.testimonials .carousel--btn-prev',
      },
      pagination: false,
    },
  },
});