/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';

// start the Stimulus application
import 'jquery';
import 'bootstrap';
import 'rangeslider.js';

import './js/header/header.js';
import './js/configurator/configurator.js';
import './js/tabs/tabs.js';
import './js/form/form.js';
import './js/offer/offer.js';
import './js/offer/show-modal.js';
import './js/swiper/swiper.js';
import './js/rangeslider/rangeslider.js';
import './js/offer/search.js';
import './js/glightbox/glightbox.js';
import './js/contact/contact.js';
import './js/contact/newsletter.js'
import './js/footer/cookies.js';