$(() => {
    // fetchModalData($('#check-availability').data('url'));
    addOnItemClickListener();
});

const fetchModalData = (url) => {
    $.ajax({
        method: 'GET',
        url: url,
    }).then((data) => {
        $('#offer-modal-content').html(data);
        addFormOnSubmitListener();
        // $('#product-price-modal').html($('#calculator-form-result').text())
    })
}

const addOnItemClickListener = () => {
    $('.product-item').on('click', (e) => {
        e.preventDefault();
        fetchModalData($(e.target).data('url'));
    });
    $('.show-modal-offer-btn').on('click', (e) => {
        e.preventDefault();
        fetchModalData($(e.target).data('url'));
    });
}

const addFormOnSubmitListener = () => {
    let calculatorOfferForm = $('#calculator-offer-form');
    let offerForm = $('#send-offer-form');
    offerForm.on('submit', (e) => {
        e.preventDefault();
        let installment = $('#calculator-form-result').text();
        let form = e.target;
        let financingType = $("#calculator-type-form input[type='radio']:checked").val();
        let url = `${form.action}?${calculatorOfferForm.serialize()}&calculator[installment]=${installment}&calculator[financingType]=${financingType}`;

        $.ajax({
            method: form.method,
            url: url,
            data: $(form).serialize(),
        }).then(function (data) {
            $('#offer-modal-content').html(data);
            addFormOnSubmitListener();
            $('#thank-you-month-price').html($('#calculator-form-result').text())
        })
    });
};
