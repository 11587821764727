var $element = $('input[type="range"]');

$element
  .rangeslider({
    polyfill: false,
    rangeClass: 'form-range-input',
    fillClass: 'form-range-track',
    handleClass: 'form-range-thumb',
    onInit: function() {
      var $handle = $('.form-range-thumb', this.$range);
      updateHandle($handle[0], this.value);
    }
  })
  .on('input', function(e) {
    var $handle = $('.form-range-thumb', e.target.nextSibling);
    updateHandle($handle[0], this.value);
  });

function updateHandle(el, val) {
  el.textContent = val;
}