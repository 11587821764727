import GLightbox from 'glightbox';
$(() => {
    lightbox();
})

const lightbox = () => {
    GLightbox({
        selector: '.glightbox',
        touchNavigation: true,
        loop: true,
        autoplayVideos: true
    });
}

export default lightbox;
