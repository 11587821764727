$(() => {
    addConfiguratorFormOnChangeListener();
})

var parametersBtn = document.querySelector('.hero--configurator .btn.btn-link');
if (parametersBtn) {
    var parametersBtnHide = document.querySelector('.hero--configurator .btn.btn-secondary-outline');
    var parametersClass = document.querySelector('.hero--configurator-extra-info');
    var parametersAria = parametersBtn.getAttribute("aria-expanded");
    parametersBtn.onclick = function(e) {
        e.preventDefault();
        parametersClass.classList.toggle('is-visible');
        parametersBtnHide.classList.toggle('visually-hidden');

        if (parametersAria == "true"){
            parametersAria = "false"
        } else {
            parametersAria = "true"
        }
        parametersBtn.setAttribute("aria-expanded", parametersAria);
    }
}

const addConfiguratorFormOnChangeListener = () => {
    $('#configurator-filter-form').change(e => {
        $.ajax({
            method: 'GET',
            url: `${$(e.currentTarget).data('url')}?${$(e.currentTarget).serialize()}`,
        }).then(data => {
            $("#configurator-filter-form").replaceWith(data);
            addConfiguratorFormOnChangeListener()
        })
    });
}
