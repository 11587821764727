import lightbox from "../glightbox/glightbox";

$(() => {
    // const calculator = $('#calculator-offer-form');
    // if (calculator.length === 0) {
    //     return;
    // }
    //
    // setCalculatorPrice();
    // fetchCalculatorResult(calculator);
    // handleCalculatorForm();
    onOfferClick();
});

const setCalculatorPrice = () => {
    let price = $('#net-price').data('price');

    if (price !== undefined && price > 0) {
        price = price / 100;
        $('#calculator_netPrice').val(price);
        $('#calculator_grossPrice').val(price * 0.23 + price);
    }
}

const handleCalculatorForm = () => {
    const calculator = $('#calculator-offer-form');
    calculator.on('submit', (e) => e.preventDefault());
    calculator.on('change', () => fetchCalculatorResult(calculator));
}

const fetchCalculatorResult = (calculator) => {
    $.ajax({
        url: `${calculator.attr('action')}?${calculator.serialize()}`,
        method: calculator.attr('method')
    }).then((data) => {
        if (data.result !== null) {
            $('#calculator-form-result').html(data.result.installment);
            $('#calculator-redemption-value').html(data.result.redemptionValue);
            $('#product-price-modal').html($('#calculator-form-result').text())
        }
    })
}

// Filters/calculator widget
const filterBtn = document.querySelector('.btn.icon-filter');
if (filterBtn) {
    let filterClass = document.querySelector('.product-filters');

    filterBtn.onclick = function (e) {
        e.preventDefault();
        filterClass.classList.toggle('is-visible');
    }
}

const onOfferClick = () => {
    $('li.product-list--item').on('click', (e) => {
        $.ajax({
            method: 'GET',
            url: $(e.currentTarget).data('tabs-url'),
        }).then(data => {
            $('#offer-tabs').html(data.html);
            // updateCalculator(data.price)
        })
        $.ajax({
            method: 'GET',
            url: $(e.currentTarget).data('gallery-url'),
        }).then(data => {
            $('#offer-gallery').html(data);
            lightbox();
        })
    });
}

const updateCalculator = (newNetPrice) => {
    $('#net-price').data('price', newNetPrice)
    setCalculatorPrice();
    fetchCalculatorResult($('#calculator-offer-form'))
}
