$(() => {
    onSubmitNewsletterForm();
});

const onSubmitNewsletterForm = () => {
    const form = $('#newsletter-form')
    form.on('submit', (e) => {
        e.preventDefault();
        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize()
        }).then(response => {
            $('#newsletter').replaceWith(response.html)
            onSubmitNewsletterForm();
        })
    })
}
