// Create cookie
function setCookie(cookie, value, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cookie + "=" + value + ";" + expires + ";path=/";
}

// Delete cookie
function deleteCookie(cookie) {
  const d = new Date();
  d.setTime(d.getTime() + (24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cookie + "=;" + expires + ";path=/";
}

// Read cookie
function getCookie(cookie) {
  let name = cookie + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

// Set cookie consent
function acceptCookieConsent(){
  deleteCookie('user_cookie_consent');
  setCookie('user_cookie_consent', 1, 30);
  cookieBanner.classList.add('hidden');
}

//cookie banner implementation
const consentBtn = document.getElementById('cookieConsent');
const cookieBanner = document.getElementById("cookieNotice");

consentBtn.onclick = () => acceptCookieConsent()

//checking if accepted
let cookie_consent = getCookie("user_cookie_consent");

if(cookie_consent === ""){
    cookieBanner.classList.remove('hidden');
}