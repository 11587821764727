$(() => {
    disableFormSubmit();
    addOnInputListenerToSearchInput();
});

const addOnInputListenerToSearchInput = () => {
    $('#search_value').on("input", (e) => {
        e.preventDefault();
        searchOffers();
    });
}

const searchOffers = () => {
    let searchForm = $('#search-offer-form');
    let url = `${searchForm.attr('action')}?${searchForm.serialize()}`;
    $.ajax({
        method: searchForm.attr('method'),
        url: url,
    }).then(function (data) {
        $('#search-result').html(data.html);
    });
}

const disableFormSubmit = () => {
    $('#search-offer-form').on('submit', (e) => {
        e.preventDefault();
    });
}
