// Fixed header
const header = document.getElementById('header');
if (header) {
    window.onscroll = () => fixedHeader(header.offsetTop);
}

const fixedHeader = (fixed) => {
    if (window.pageYOffset > fixed) {
        header.classList.add('fixed');
    } else {
        header.classList.remove('fixed');
    }
}

// Hamburger menu
const mainNavBtn = document.querySelector('.btn.icon-hamburger');
if (mainNavBtn) {
    const mainNavClass = document.querySelector('.main-nav');
    mainNavBtn.onclick = (e) => onClickMainNavBtn(e, mainNavClass)
}

const onClickMainNavBtn = (e, mainNavClass) => {
    e.preventDefault();

    mainNavClass.classList.toggle('is-visible');
    let mainNavAria = mainNavBtn.getAttribute("aria-expanded")

    if (mainNavAria === "true") {
        mainNavAria = "false"
    } else {
        mainNavAria = "true"
    }

    mainNavBtn.setAttribute("aria-expanded", mainNavAria);
}

// Search widget
const searchBtn = document.querySelector('.btn.icon-search');
if (searchBtn) {
    let searchClass = document.querySelector('.header--search');
    let searchAria = searchBtn.getAttribute("aria-expanded");

    searchBtn.onclick = function (e) {
        e.preventDefault();
        searchClass.classList.toggle('is-visible');

        if (searchAria == "true") {
            searchAria = "false"
        } else {
            searchAria = "true"
        }
        searchBtn.setAttribute("aria-expanded", searchAria);
    }
}

if (window.innerWidth < 400) {
    document.querySelector('.header--search-input').placeholder = 'Czego szukasz?';
}
